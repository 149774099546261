import React from "react"
import { graphql } from "gatsby"

import style from "./index.module.css"
import ParallaxHooklessImage from "../components/parallax-hookless-image/ParallaxHooklessImage"
import TextBlock from "../components/text-block/TextBlock"
import { Head } from "../components/head/Head"
import MarkdownContainer from "../components/markdown-container/MarkdownContainer"

export default ({ data }) => {
  return (
    <React.Fragment>
      <Head title="Alex & Alexis - Home" imageSrc={data.portrait.childImageSharp.fluid.src} />
      <ParallaxHooklessImage
        src={data.portrait.childImageSharp.fluid.src}
        height={data.portrait.childImageSharp.fluid.presentationHeight}
        width={data.portrait.childImageSharp.fluid.presentationWidth}
      >
        <div className={style.caption}>
          <div className={style.captionContainer}>
            <div className={style.otherText}>The Wedding of</div>
            <h1>Alex & Alexis</h1>
            <div className={style.otherText}>July 3, 2021</div>
          </div>
        </div>
      </ParallaxHooklessImage>

      <TextBlock>
        <MarkdownContainer markdownString={data.reschedule.html} />
      </TextBlock>

      <ParallaxHooklessImage
        src={data.secondPortrait.childImageSharp.fluid.src}
        height={data.secondPortrait.childImageSharp.fluid.presentationHeight}
        width={data.secondPortrait.childImageSharp.fluid.presentationWidth}
      />
    </React.Fragment>
  )
}

export const query = graphql`
  {
    portrait: file(relativePath: { eq: "family-photo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3440) {
          src
          presentationHeight
          presentationWidth
        }
      }
    }
    secondPortrait: file(relativePath: { eq: "cement-holding-hands.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3440) {
          src
          presentationHeight
          presentationWidth
        }
      }
    }

    reschedule: markdownRemark(frontmatter: { title: { eq: "reschedule" } }) {
      html
    }
  }
`
